<template>
<div class="history-component worker-profile basic-padding">
    <SidebarEntry :title="$t('admin.payroll.detail.creation-date')" :value="historyTransaction.creationDate" />
    <SidebarEntry :title="$t('admin.history.author')" :value="historyTransaction.author" />
    <SidebarEntry :title="$t('admin.active-worker.table.status-reason')" :value="historyTransaction.reason" />
    <SidebarEntry :title="$t('admin.history.change')" :value="historyTransaction.change" />
</div>
</template>

<script>
import SidebarEntry from "@/views/sidebar/basic/SidebarEntry.vue";

export default {
    name: "HistoryComponent",
    components: {SidebarEntry},
    props: {
        historyTransaction: {
            type: Object,
            required: true,
            default: () => ({
                "creationDate": "2022-09-20T14:49:51",
                "author": "giona.granchelli@nl.abnamro.com",
                "reason": "asdadasdas",
                "change": "Transaction ID Ext changed from [4500EXTNAWZSb5vY4UPHm8t5oFl6HesipyGMpdFTthuImKr] to [4500EXTNAWZSb5vY4UPHm8t5oFl6HesipyGMpdFTthuImKr_REJ1]."
            })

        }
    },

};
</script>

<style scoped>
.history-component{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  height: auto;
  border: 1px solid #DEDEDE;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>