<template>
  <div class="history-container">
    <div class="history-empty" v-if="history.length===0">
      {{ $t('admin.history.empty')}}
    </div>
    <HistoryComponent v-for="tr in history" :key="tr.id" :history-transaction="tr"/>
  </div>
</template>

<script>
import HistoryComponent from "@/components/Transaction/TransactionHistory/HistoryComponent.vue";

export default {
    name: "HistoryContainer",
    components: {HistoryComponent},
    props:{
        history: {
            type: Array,
            required: true,
            default : () =>([
            ])
        }
    },
    
};
</script>

<style scoped>
.history-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  overflow-y: scroll;
}
</style>