<template>
  <div>
    <SidebarHeader :handle-sidebar="handleSidebar" :is-back-button-active="true"
                   :title="$t('admin.transactions.update.title')"/>
    <div class="worker-profile basic-padding scrollable-sidebar">
      <SideTitleSidebarComponent :title="$t('admin.history.title')"/>
      <HistoryContainer v-if="!isLoading && !error" :history="transactionHistory"/>
      <Loading v-if="isLoading && !error" :is-loading="isLoading"/>
      <div v-if="!isLoading && error && transactionHistory.length === 0" class="history-empty">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
import ApiUtils from "@/utils/ApiUtils.vue";
import SidebarHeader from "@/components/Layout/Header/SidebarHeader.vue";
import SideTitleSidebarComponent from "@/views/sidebar/basic/SideTitleSidebarComponent.vue";
import HistoryContainer from "@/components/Transaction/TransactionHistory/HistoryContainer.vue";
import Loading from "@/components/Layout/Loading/Loading.vue";

export default {
    name: "TransactionHistory",
    components: {Loading, HistoryContainer, SideTitleSidebarComponent, SidebarHeader},
    mixins: [ApiUtils],
    data() {
        return {
            transactionHistory: [],
            isLoading: false,
            isError: false,
            error: "",
        };
    },
    props: {
        handleSidebar: {
            type: Function,
            required: true,
        },
        transactionId: {
            type: Number,
            required: true,
            default: () => -1
        },
        isWal: {
            type: Boolean,
            required: true,
            default: () => false
        },
        isPis: {
            type: Boolean,
            required: true,
            default: () => false
        },
        isPayout: {
            type: Boolean,
            required: true,
            default: () => false
        },
    },
    async mounted() {
        await this.fetchTransactionDetails();
    },
    methods: {
        async fetchTransactionDetails() {
            this.isLoading = true;
            await this.setHeader();
            await this.$api.getTransactionDetail(this.isWal, this.isPis, this.isPayout, this.transactionId)
                .then(response => {
                    const historyList = response.data.paymentHistory || [];
                    this.transactionHistory = historyList;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.isError = true;
                    this.error = `Impossible to retrieve transaction history details for ${this.transactionId}`;
                    console.error(error);
                });
        }
    }
};
</script>