<template>
  <div v-if="transactions" class="worker-details">
    <SidebarHeader
        :handle-sidebar="handleSidebar"
        :is-back-button-active="false"
        :is-menu-enabled="false"
        :title="getTitle()"
    />
    <Loading :is-loading="isLoading" :text="loadingText"/>
    <b-container v-if="!isLoading" class="worker-profile basic-padding scrollable-sidebar" fluid>
      <SideTitleSidebarComponent :title="getSubtitle()"/>
      <div class="table-container">
        <b-table :fields="fields" :items="transactions"
                 :sticky-header="getHeightOfTable()"
                 borderless
                 class="approve-table"
                 fixed head-variant="light" hover no-sort-reset
                 show-empty small>
          <template #head(uid)>
            <TextContainer :text="$t('admin.transactions.table.userid')"/>
          </template>
          <template #head(refid)>
            <TextContainer text="Ref Id"/>
          </template>
          <template #head(paymentType)>
            <TextContainer :text="$t('admin.transactions.table.type')"/>
          </template>
          <template #head(amount)>
            <TextContainer :text="$t('admin.transactions.table.amount')" class-name="text-right"/>
          </template>
          <template #cell(uid)="data">
            <TextContainer :text="data.item.uid.toString()"/>
          </template>
          <template #cell(refid)="data">
            <TextContainer :text="data.item.refid"/>
          </template>
          <template #cell(paymentType)="data">
            <TextContainer :text="getPayType(data.item)"/>
          </template>
          <template #cell(amount)="data">
            <TextContainer :text="data.item.amount ? getAmount(data.item.amount) : getAmount(data.item.totalAmount)"
                           class-name="text-right"/>
          </template>
        </b-table>
        <b-row class="custom-footer">
          <b-col cols="3">
            <b>{{ $t('admin.transactions.management.table.overview.total') }}</b>
          </b-col>
          <b-col>&nbsp;</b-col>
          <b-col>&nbsp;</b-col>
          <b-col cols="3"><b>{{ getTotalAmount() }}</b></b-col>
        </b-row>
      </div>
      <div v-if="!isApprove">
        <BlankLine :number-of-rows="1"/>
        <SidebarEntry :title="$t('admin.transactions.management.reason.title')" value=" "/>
        <SidebarTextArea
            :max="maxCharReason"
            :min="minCharReason"
            :set-motivation="setReasonText"
        />
      </div>
    </b-container>
    <ButtonContainer is-wrapped>
      <template v-slot:mainButton>
        <MainButton
            v-if="isApprove"
            v-b-toggle:sidebar-refuse
            :button-text="getButtonText()"
            :is-active="isFormValid"
            :on-click="approveTransactions"
        />
        <MainButton
            v-if="!isApprove"
            v-b-toggle:sidebar-refuse
            :button-text="getButtonText()"
            :is-active="isFormValid"
            :on-click="refuseTransactions"
        />
      </template>
    </ButtonContainer>
  </div>
</template>

<script>
import "@/utils/ArrayExtension";
import ButtonContainer from "@/components/Buttons/ButtonContainer.vue";
import fields from "@/static/transactions/approve_refuse_header";
import FormatUtils from "@/utils/FormatUtils.vue";
import MainButton from "@/components/Buttons/MainButton.vue";
import PaymentType, {getPaymentType, isAnyPayout} from "@/utils/PaymentType";
import SidebarEntry from "@/views/sidebar/basic/SidebarEntry.vue";
import SidebarHeader from "@/components/Layout/Header/SidebarHeader.vue";
import SidebarTextArea from "@/views/sidebar/basic/SidebarTextArea.vue";
import SideTitleSidebarComponent from "@/views/sidebar/basic/SideTitleSidebarComponent.vue";
import TextContainer from "@/components/TextFormat/TextContainer.vue";
import ApiUtils from "@/utils/ApiUtils.vue";
import ToasterUtils from "@/utils/ToasterUtils.vue";
import PaymentInfo from "@/model/Payments/PaymentInfo";
import ConfirmPaymentsRequest from "@/model/Payments/ConfirmPaymentsRequest";
import RefusePaymentsRequest from "@/model/Payments/RefusePaymentsRequest";
import Loading from "@/components/Layout/Loading/Loading.vue";
import BlankLine from "@/components/Blank/BlankLine.vue";

export default {
    name: "TransactionBatchManagementSidebar",
    components: {
        BlankLine,
        Loading,
        MainButton,
        SidebarTextArea,
        SidebarEntry,
        TextContainer, ButtonContainer, SideTitleSidebarComponent, SidebarHeader
    },
    mixins: [FormatUtils, ApiUtils, ToasterUtils],
    data() {
        return {
            fields: fields,
            reasonText: "",
            maxCharReason: 400,
            minCharReason: 10,
            tableBigSize: "65vh",
            tableLittleSize: "45vh",
            isLoading: false,
            getPaymentType: getPaymentType,
            isAnyPayout: isAnyPayout,
        };
    },
    computed: {
        isFormValid() {
            if (this.isApprove) {
                return this.transactions.length > 0;
            }
            return this.isReasonValid && this.transactions.length > 0;
        },
        isReasonValid() {
            return this.reasonText.length >= this.minCharReason && this.reasonText.length <= this.maxCharReason;
        },
        loadingText() {
            return this.isApprove ? this.$t('admin.common.loading-text.transactions.approve') : this.$t('admin.common.loading-text.transactions.refuse');
        }
    },
    props: {
        isApprove: {
            type: Boolean,
            required: false,
            default: () => false
        },
        handleSidebar: {
            type: Function,
            required: false
        },
        transactions: {
            type: Array,
            required: true,
            default: () => {
                return [];
            }
        },
        table: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        isWal: {
            type: Boolean,
            required: true,
            default: () => false
        }
    },
    methods: {
        getCompanyName() {
            return this.transactions.map((transaction) => transaction.companyName).simpleDistinct();
        },
        getTitle() {
            return this.isApprove ? this.$t('admin.transactions.management.title.approve') : this.$t('admin.transactions.management.title.refuse');
        },
        getSubtitle() {
            return this.isApprove ?
                this.$t('admin.transactions.management.subtitle.approve', {
                    transactionNumber: this.transactions.length,
                    companyName: this.getCompanyName()
                }) :
                this.$t('admin.transactions.management.subtitle.refuse', {
                    transactionNumber: this.transactions.length,
                    companyName: this.getCompanyName()
                });
        },
        getButtonText() {
            return this.isApprove ?
                this.$t('admin.transactions.management.action.approve', {transactionNumber: this.transactions.length}) :
                this.$t('admin.transactions.management.action.refuse', {transactionNumber: this.transactions.length});
        },
        getPayType(item) {
            return item.paymentType === PaymentType.WALCADJ.symbol ?
                this.$t(getPaymentType(item.status).name) :
                this.$t(getPaymentType(item.paymentType).name);
        },
        setReasonText(text) {
            this.reasonText = text;
        },
        mapTransactionToPaymentInfo(transactions) {
            return transactions.map(t => {
                let type;
                const isPayout = this.isAnyPayout(t.paymentType);
                switch (true) {
                case (t.paymentType === PaymentType.PIS.symbol):
                    type = PaymentType.PIS.symbol;
                    break;
                case (isPayout && this.isWal):
                    type = PaymentType.WALOUT.symbol;
                    break;
                case (isPayout && !this.isWal):
                    type = PaymentType.WALCOUT.symbol;
                    break;
                case (!isPayout && this.isWal):
                    type = PaymentType.WAL.symbol;
                    break;
                case (!isPayout && !this.isWal):
                    type = PaymentType.WALC.symbol;
                    break;
                default:
                    type = t.paymentType;
                }
                return new PaymentInfo(parseInt(t.id), type);
            });
        },
        async approveTransactions() {
            try {
                this.isLoading = true;
                await this.setHeader();
                const payload = this.mapTransactionToPaymentInfo(this.transactions);
                await this.$api.confirmBatchTransactions(new ConfirmPaymentsRequest(payload))
                    .then(response => {
                        this.isLoading = false;
                        this.table.refresh();
                        this.handleSidebar();
                        this.makeToast(response.status === 200, response.data.message);
                    }).catch(error => {
                        this.isLoading = false;
                        this.makeToast(false, error.message);
                    });
            } catch (e) {
                this.isLoading = false;
                this.makeToast(false, e.toLocaleString());
            }
        },
        async refuseTransactions() {
            try {
                this.isLoading = true;
                await this.setHeader();
                const payload = this.mapTransactionToPaymentInfo(this.transactions);
                await this.$api.refuseBatchTransactions(new RefusePaymentsRequest(payload, this.reasonText))
                    .then(response => {
                        this.isLoading = false;
                        this.table.refresh();
                        this.handleSidebar();
                        this.makeToast(response.status === 200, response.data.message);
                    }).catch(error => {
                        this.isLoading = false;
                        this.makeToast(false, error.message);
                    });
            } catch (e) {
                this.isLoading = false;
                this.makeToast(false, e.toLocaleString());
            }
        },
        getHeightOfTable() {
            return this.isApprove ? this.tableBigSize : this.tableLittleSize;
        },
        getTotalAmount() {
            if (this.transactions.length === 0) return this.getAmount(0);
            return this.getAmount(
                this.transactions
                    .map(t => parseFloat(t.amount))
                    .reduce((acc, value) => acc + value)
            );
        }
    }
};
</script>

<style scoped>
.approve-table {
  border: 1px solid #DEDEDE;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom: none;
  cursor: pointer;
  margin: 0;
}

.custom-footer {
  background-color: #e9ecef;
  border: 1px solid #DEDEDE;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: none;
  cursor: pointer;
  margin: 0;
  color: #495057;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>